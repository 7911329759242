body {
	background: #f2f2f2 !important;
}

.input-phone-number input {
	width: "100%" !important;
	border: none;
	border-bottom: 1px solid #9e9e9e;
	outline: none;
	box-shadow: none;
}

.input-phone-number input:focus {
	border-bottom: 2px solid #08a4ec;
}

.input-phone-number input:not(:focus) {
	border-bottom: 1px solid #9e9e9e;
}

.input-phone-number input:not(:focus):hover {
	border-bottom: 2px solid #000000; /* Border color on hover */
}
