.order-place-card {
	background: white;
	border-radius: 12px;
	width: 500px;
	box-shadow: 0.25em 0.25em 0.75em rgb(0 0 0 / 25%), 0.125em 0.125em 0.25em rgb(0 0 0 / 25%);
	z-index: 2;
}

@media only screen and (max-width: 768px) {
	.order-place-card {
		background: white;
		border-radius: 12px;
		width: 100%;
	}
}

.tickMark {
	font-size: 250px;
	background: -webkit-linear-gradient(90deg, #00a2ea, #6bb9dd);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

@media only screen and (max-width: 768px) {
	.tickMark {
		font-size: 200px;
	}
}

.w4rAnimated_checkmark svg {
	width: 200px;
	display: block;
	margin: 40px auto 0;
}
.w4rAnimated_checkmark .path {
	stroke-dasharray: 1000;
	stroke-dashoffset: 0;
}
.w4rAnimated_checkmark .path.circle {
	-webkit-animation: dash 0.9s ease-in-out;
	animation: dash 2.5s ease-in-out;
}
.w4rAnimated_checkmark .path.line {
	stroke-dashoffset: 1000;
	-webkit-animation: dash 0.9s 0.35s ease-in-out forwards;
	animation: dash 3s 0.35s ease-in-out forwards;
}
.w4rAnimated_checkmark .path.check {
	stroke-dashoffset: -100;
	-webkit-animation: dash-check 0.9s 0.35s ease-in-out forwards;
	animation: dash-check 2s 0.35s ease-in-out forwards;
	animation-delay: 1s;
}

@-webkit-keyframes dash {
	0% {
		stroke-dashoffset: 1000;
	}
	100% {
		stroke-dashoffset: 0;
	}
}
@keyframes dash {
	0% {
		stroke-dashoffset: 1000;
	}
	100% {
		stroke-dashoffset: 0;
	}
}
@-webkit-keyframes dash-check {
	0% {
		stroke-dashoffset: -100;
	}
	100% {
		stroke-dashoffset: 900;
	}
}
@keyframes dash-check {
	0% {
		stroke-dashoffset: -100;
	}
	100% {
		stroke-dashoffset: 900;
	}
}
